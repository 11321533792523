import Image from "next/image"
import { useState } from "react"
import { useTranslations } from "use-intl"
import { richResolver } from "utils"

const usFlagSmall = require("node_modules/uswds/dist/img/us_flag_small.png")
const iconDotGov = require("node_modules/uswds/dist/img/icon-dot-gov.svg")
const iconHttps = require("node_modules/uswds/dist/img/icon-https.svg")

// https://designsystem.digital.gov/components/banner/
export function OfficialBanner() {
  const [isOpen, setOpen] = useState(false)
  const t = useTranslations("Shared.Header.OfficialBanner")

  const toggleOpen = () => setOpen(!isOpen)

  return (
    <section className="usa-banner" aria-label="Official government website">
      <div className="usa-accordion">
        <header
          className="usa-banner__header"
          style={{
            minHeight: "1rem",
            paddingBottom: "0.1rem",
            paddingTop: "0.1rem",
          }}
        >
          <div className="usa-banner__inner">
            <div className="grid-col-auto padding-top-2px">
              <span className="usa-banner__header-flag">
                <Image src={usFlagSmall} alt="U.S. flag" layout="intrinsic" />
              </span>
            </div>
            <div className="grid-col-fill tablet:grid-col-auto display-flex flex-align-center flex-justify">
              <p className="usa-banner__header-text">{t("an_official")}</p>
              <p className="usa-banner__header-action" aria-hidden="true">
                {t("heres_how")}
              </p>
            </div>
            <button
              className="usa-accordion__button usa-banner__button"
              aria-expanded="false"
              aria-controls="gov-banner-default"
              onClick={toggleOpen}
            >
              <span className="usa-banner__button-text">{t("heres_how")}</span>
            </button>
          </div>
        </header>

        {isOpen && (
          <div
            className="usa-banner__content grid-container"
            id="gov-banner-default"
          >
            <div className="text-xs sm:text-sm flex flex-col md:grid md:grid-cols-2">
              <div className="usa-banner__guidance">
                <span className="usa-banner__icon usa-media-block__img">
                  <Image src={iconDotGov} alt="" layout="intrinsic" />
                </span>
                <div className="usa-media-block__body">
                  <p>
                    <strong>{t("official_websites")}</strong>
                    <br />
                    {t.rich("a_gov", richResolver)}
                  </p>
                </div>
              </div>
              <div className="usa-banner__guidance">
                <span className="usa-banner__icon usa-media-block__img">
                  <Image src={iconHttps} alt="" layout="intrinsic" />
                </span>
                <div className="usa-media-block__body">
                  <p>
                    <strong>{t("secure_gov")}</strong>
                    <br />
                    {t.rich("a_lock", richResolver)} (
                    <span className="icon-lock">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="52"
                        height="64"
                        viewBox="0 0 52 64"
                        className="usa-banner__lock-image display-inline"
                        role="img"
                        aria-labelledby="banner-lock-title-default banner-lock-description-default"
                        focusable="false"
                      >
                        <title id="banner-lock-title-default">
                          {t("lock")}
                        </title>
                        <desc id="banner-lock-description-default">
                          {t("a_locked")}
                        </desc>
                        <path
                          fill="#000000"
                          fillRule="evenodd"
                          d={
                            `M26 0c10.493 0 19 8.507 19 19v9h3a4 4 0 0 1 4 4v28a4 4 0` +
                            ` 0 1-4 4H4a4 4 0 0 1-4-4V32a4 4 0 0 1 4-4h3v-9C7 8.507 15.507 0 26 0zm0 8c-5.979 0-10.843 4.77-10.996 10.712L15 19v9h22v-9c0-6.075-4.925-11-11-11z`
                          }
                        />
                      </svg>
                    </span>
                    ) {t.rich("or_https", richResolver)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}
