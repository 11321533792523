import { SkipLinks } from "./SkipLinks"
import { OfficialBanner } from "./OfficialBanner"
import { DepartmentOfLaborBanner } from "./DepartmentOfLabor"
import { MainMenu } from "./MainMenu"

export function Header() {
  return (
    <>
      <SkipLinks />
      <OfficialBanner />
      <DepartmentOfLaborBanner />
      <MainMenu />
    </>
  )
}
