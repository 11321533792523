import Link from "next/link"

export const richResolver = {
  br: (children) => (
    <>
      <br />
      {children}
    </>
  ),
  li: (children) => <li>{children}</li>,
  strong: (children) => <strong>{children}</strong>,
  contact: (children) => (
    <Link href="/contact" passHref>
      <a className="text-dolBlue font-bold hover:underline">{children}</a>
    </Link>
  ),
  contactBlack: (children) => (
    <Link href="/contact" passHref>
      <a className="font-bold hover:underline">{children}</a>
    </Link>
  ),
}
