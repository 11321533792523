export async function callNextAPI({ url, payload }) {
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ payload }),
    })
    return await res.json()
  } catch (e) {
    throw new Error(`Error occurred during NextJS API call: ${e}`)
  }
}
