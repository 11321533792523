import getConfig from "next/config"
const { publicRuntimeConfig } = getConfig()
export const currentEnv = publicRuntimeConfig.env
export const currentDomain =
  publicRuntimeConfig.currentDomain || `https://dev.seasonaljobs.dol.gov`
export const domain =
  publicRuntimeConfig.domain || `https://dev.api.seasonaljobs.dol.gov`

export const DATAHUB_DOCS = `${domain}/datahub/?api-version=2020-06-30&%24format=application%2Fjson%3Bodata.metadata%3Dnone&search=*`
export const DATAHUB_SEARCH = `${domain}/datahub/search?api-version=2020-06-30`
export const DATAHUB_SUGGEST = `${domain}/datahub/suggest?api-version=2020-06-30`
const DATAHUB_SUBSCRIPTION_BASE_URL = `http://${process.env.OFLC_DATAHUB_SUBSCRIPTIONS_URL}:8894`
export const DATAHUB_SUBSCRIPTION_URL = `${DATAHUB_SUBSCRIPTION_BASE_URL}/datahub-subscriptions/sjSubscription`
export const DATAHUB_SUBSCRIPTION_PW =
  process.env.OFLC_DATAHUB_SUBSCRIPTION_PROXY_PASSWORD

export const DRUPAL_TRANSLATIONS = `${domain}/messages` // local
export const AZURE_MAPS_KEY = process.env.NEXT_PUBLIC_AZURE_MAPS_KEY

export const RECAPTCHA_SITE_KEY =
  publicRuntimeConfig.recaptchaSiteKey ||
  "6LcUSukcAAAAAFdwaVKZ3TADeoIviLgKBRNG4rhL"
export const OG_URL = process.env.NEXT_PUBLIC_OG_IMAGE_URL

export const redisHost = process.env.REDIS_HOST
export const redisKey = process.env.REDIS_KEY
export const redisPort = process.env.REDIS_PORT
export const redisSSL = process.env.REDIS_SSL

export const JMETER_TESTING = process.env.SJ_JMETER_TESTING
export const K8S_NAMESPACE = process.env.K8S_NAMESPACE
