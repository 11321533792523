import { isNa, normalizeProtocol } from "utils"

export function createLinkType({ type, link, ...props }) {
  if (isNa(link)) {
    return null
  }

  // Constructing the href based on the type
  const href =
    type === "ext"
      ? link
      : type
      ? `${type}:${link}`
      : `${normalizeProtocol(link)}`

  // Additional properties for the <a> tag when 'type' is not specified
  const extraProps = !type ? { target: "_blank" } : {}

  return (
    <a
      href={href}
      className="text-dolBlue hover:underline"
      {...props}
      {...extraProps}
    />
  )
}
