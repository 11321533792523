import { findJobsDefaultValues, advancedSearchDefaultValues } from "const"

export function getDefaultValues(which) {
  if (which === "jobs") {
    return findJobsDefaultValues
  } else if (which === "archive") {
    return advancedSearchDefaultValues
  } else {
    return findJobsDefaultValues
  }
}
