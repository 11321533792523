export function Section({
  heading,
  headingOverride,
  headingTw,
  gridContainer = true,
  gridContainerHeading = true,
  bg = "unset",
  tw = "",
  children,
  ...rest
}) {
  return (
    <section className={`usa-section bg-${bg} pb-0 pt-6 ${tw}`} {...rest}>
      <div className={gridContainerHeading ? "grid-container" : ""}>
        {headingOverride
          ? headingOverride
          : heading && (
              <h2
                className={`font-heading-xl font-medium text-gray-800 ${headingTw}`}
              >
                {heading}
              </h2>
            )}
      </div>
      <div className={gridContainer ? "grid-container" : ""}>{children}</div>
    </section>
  )
}
