const UNITED_STATES = "US"
const AMERICAN_SAMOA = "AS"
const GUAM = "GU"
const NORTHERN_MARIANA_ISLANDS = "MP"
const PUERTO_RICO = "PR"
const UNITED_STATES_MINOR_OUTLYING_ISLANDS = "UM"
const VIRGIN_ISLANDS = "VI"

export const supportedCountryCodes = [
  UNITED_STATES,
  AMERICAN_SAMOA,
  GUAM,
  NORTHERN_MARIANA_ISLANDS,
  PUERTO_RICO,
  UNITED_STATES_MINOR_OUTLYING_ISLANDS,
  VIRGIN_ISLANDS,
]
