import Image from "next/image"
import { useTranslations } from "use-intl"

export function MSAttribution({ tw }) {
  const t = useTranslations("Shared")
  return (
    <a
      className="py-2"
      target="_blank"
      rel="noreferrer"
      href="http://aka.ms/MicrosoftTranslatorAttribution"
    >
      <div
        className={`opacity-90 hover:opacity-100 flex flex-col align-center items-center ${
          tw || ""
        }`}
      >
        <p style={{ color: "#737373" }} className="text-xs font-normal">
          {t("translated_by")}
        </p>
        <div
          className="relative mt-0.5"
          style={{
            height: "16px",
            width: "75px",
          }}
        >
          <Image src="/logo-msft.png" alt="Microsoft" layout="fill" />
        </div>
      </div>
    </a>
  )
}
