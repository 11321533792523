import { DATAHUB_DOCS } from "env"

export async function fetchDatahubDocs(key) {
  try {
    const res = await fetch(`${DATAHUB_DOCS}${key}`, {
      method: "GET",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        "User-Agent": "*",
      },
    })

    const data = await res.json()

    if (data.error) {
      throw data.error.message
    }

    return data.value
  } catch (e) {
    throw `Could not fetch datahub docs with key: ${JSON.stringify(key)}: ${e}`
  }
}
