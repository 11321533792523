import { configureStore } from "@reduxjs/toolkit"
import jobsSlice from "./jobsSlice"
import archiveSlice from "./archiveSlice"
import userSlice from "./userSlice"

export const store = configureStore({
  reducer: {
    jobs: jobsSlice,
    archive: archiveSlice,
    user: userSlice,
  },
})
