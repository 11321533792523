import sanitizeHtml from "sanitize-html"

// sanitize objects
export function sanitize(dirty) {
  if (dirty && dirty.constructor === Object) {
    let clone = JSON.parse(JSON.stringify(dirty))
    let cloneKeys = Object.keys(clone)
    for (let cloneKey of cloneKeys) {
      clone[cloneKey] = sanitizeHtml(clone[cloneKey], {
        allowedTags: [],
        allowedAttributes: {},
      })
    }
    return clone
  } else if (dirty.constructor === String) {
    return sanitizeHtml(dirty, {
      allowedTags: [],
      allowedAttributes: {},
    })
  }
  return null
}
