export async function postContactForm({ form, code }) {
  try {
    const res = await fetch("/api/contact", {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ form, code }),
    })
    return await res.json()
  } catch (e) {
    throw new Error(`Could not post contact form: ${e}`)
  }
}
