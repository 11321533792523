export function markMatches({ string, mark, parentId }) {
  if (!mark || !mark.trim()) {
    return <span>{string}</span>
  }
  const escapeRegExp = (str = "") =>
    str.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1")
  const regex = new RegExp(`(${escapeRegExp(mark)})`, "gi")
  const parts = string.split(regex)
  return (
    <span>
      {parts
        .filter((part) => part)
        .map((part, i) =>
          regex.test(part) ? (
            <mark
              id={`${parentId}-${i}`}
              className="bg-transparent font-bold"
              key={i}
            >
              {part}
            </mark>
          ) : (
            <span key={i}>{part}</span>
          )
        )}
    </span>
  )
}
