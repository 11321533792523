import { stateRelatedList } from "const"
import { getStateFromAbbreviation } from "utils"

export function getLocationType(rawSearch) {
  if (rawSearch) {
    const uppercaseRawSearch = rawSearch?.toUpperCase()
    let search = rawSearch
    let type = "just_coords"

    const foundInStateRelatedList =
      stateRelatedList.indexOf(uppercaseRawSearch) > -1

    const zipcodeRegex = /^\d{5}(?:-\d{4})?$/

    if (zipcodeRegex.test(rawSearch)) {
      type = "zip"
    } else if (foundInStateRelatedList) {
      type = "state"
      if (rawSearch?.length === 2) {
        search = getStateFromAbbreviation(rawSearch)
      }
      search = search.toUpperCase()
    }
    return { type, search }
  }

  return { type: null, search: null }
}
