import * as yup from "yup"

// Regex from Dashboard validations
const EMAIL_REGEX = /^[a-zA-Z0-9'&!#|._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,30}$/i
// Regex for accepting latin1
const LATIN1_REGEX = /^[\u0000-\u00FF]*$/g
// Regex for displaying non-latin1 for user validation messages
const NON_LATIN1_REGEX = /([^\u0000-\u00FF])/g

const getTranslations = ({ shared = undefined, additional = undefined }) => ({
  email: shared ? shared("yup.string.email") : "Please provide a valid email",
  email_match: additional ? additional("email_match") : "Emails must match",
  illegal_char: shared ? shared("illegal_char") : "Characters not allowed",
})

const applyLatin1Validations = (schema, translations) => {
  Object.entries(schema.fields).forEach(([key, field]) => {
    if (field.type === "string") {
      // Directly append the Latin validation to the existing schema
      schema.fields[key] = field.matches(
        LATIN1_REGEX,
        (d) =>
          `${translations["illegal_char"]} ${
            d.value.match(NON_LATIN1_REGEX)?.join("") || ""
          }`
      )
    }
  })

  return yup.object().shape(schema.fields) // Return the updated schema
}

// Shared schema
export const baseSchema = (translations) =>
  yup
    .object()
    .shape({
      email: yup
        .string()
        .max(100)
        .email()
        .matches(EMAIL_REGEX, translations["email"])
        .required(),
      confirmEmail: yup
        .string()
        .max(100)
        .email()
        .matches(EMAIL_REGEX, translations["email"])
        .test("match", translations["email_match"], function () {
          return this.parent.email === this.parent.confirmEmail
        })
        .required(),
    })
    .required()

// Shared Contact schema for client & server
export const contactSchema = (shared, additional) => {
  const translations = getTranslations({ shared, additional })

  return applyLatin1Validations(
    baseSchema(translations)
      .shape({
        name: yup.string().max(50).required(),
        topic: yup
          .mixed()
          .oneOf([
            "Enhancement Suggestion or Idea",
            "Report a Bug or Defect",
            "Other Technical Question",
          ])
          .required(),
        message: yup.string().min(25).max(2000).required(),
      })
      .required(),
    translations
  )
}
// Shared Subscription schema for client & server
export const subscriptionSchema = (shared, additional) => {
  const translations = getTranslations({ shared, additional })

  return applyLatin1Validations(
    baseSchema(translations)
      .shape({
        subscriptionLabel: yup.string().max(100).required(),
        frequency: yup.string().max(100).required(),
        language: yup.string().max(100).required(),
        displayName: yup.string().max(100),
        jobSearchText: yup.string().max(100),
        jobSearchLocation: yup.string().max(100),
        jobFilterLocationMiles: yup.string().max(100),
        jobFilterWageType: yup.string().max(100),
        jobFilterBeginOnAfterDate: yup.string().max(100),
        jobFilterJobType: yup.string().max(100),
        jobSearchLocation: yup.string().max(100),
        jobSortBy: yup.string().max(100),
        azFilter: yup.string().max(100),
        azOrderBy: yup.string().max(100),
        azSearch: yup.string().max(100),
      })
      .required(),
    translations
  )
}

// Shared Job Search schema for client & server
export const jobSearchSchema = (shared) => {
  const translations = getTranslations({ shared })

  return applyLatin1Validations(
    yup
      .object()
      .shape({
        search: yup.string().max(250),
        location: yup.string().max(250),
      })
      .required(),
    translations
  )
}
