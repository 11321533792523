import dynamic from "next/dynamic"
import { Header } from "../Header"
import { Banner } from "components/Banner"
import useInView from "react-cool-inview"

const Footer = dynamic(() =>
  import("components/Footer").then((mod) => mod.Footer)
)

export function GlobalTemplate(props) {
  const { observe, inView } = useInView({
    onEnter: ({ unobserve }) => unobserve(), // only run once
  })

  return (
    <div>
      <Banner />
      <Header />
      {props.children}
      <div
        className="placeholder"
        style={!inView ? { width: "100%", height: 600 } : undefined}
        ref={observe}
      >
        {inView && <Footer />}
      </div>
    </div>
  )
}
