import { DATAHUB_SEARCH } from "env"

export async function fetchJob({ case_number }) {
  try {
    const res = await fetch(DATAHUB_SEARCH, {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        "User-Agent": "*",
      },
      body: JSON.stringify({
        search: `"${case_number}"`,
        searchFields: "case_number",
        top: 1,
      }),
    })

    const data = await res.json()

    if (data.error) {
      throw data.error.message
    }

    return data.value[0]
  } catch (e) {
    throw `Could not fetch job with case number: ${case_number}: ${JSON.stringify(
      e
    )}`
  }
}
