import getConfig from "next/config"

const { serverRuntimeConfig } = getConfig()

export async function postRecaptchaSiteVerify({ code }) {
  try {
    const res = await fetch(
      `https://www.google.com/recaptcha/api/siteverify?secret=${serverRuntimeConfig.recaptchaSecretKey}&response=${code}`,
      {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
        },
      }
    )
    return await res.json()
  } catch (e) {
    throw new Error(e)
  }
}
