import { CloseIcon } from "assets"
import { useDispatch, useSelector } from "react-redux"
import { toggleBannerOpen } from "state"
import { useTranslations } from "use-intl"
import { richResolver } from "utils"

export function Banner() {
  const t = useTranslations("Shared.Banner")
  const dispatch = useDispatch()
  const { bannerOpen } = useSelector((state) => state.user)

  const bannerEnabled = t("display") === "true"
  const color = t("color")
  const title = t("title")
  const body = t.rich("body", richResolver)

  return bannerEnabled && bannerOpen ? (
    <section className={`bg-${color || "yellow-500"} py-6`}>
      <div className="grid-container relative">
        <span className="sr-only bg-yellow-500" />
        <button
          type="button"
          className="absolute right-5 opacity-70 hover:opacity-100"
          onClick={() => dispatch(toggleBannerOpen())}
        >
          <span className="sr-only">Close Banner</span>
          <CloseIcon className="w-8 h-8" />
        </button>
        <h1 className="text-lg sm:text-xl md:text-2xl font-bold mb-2">
          {title}
        </h1>
        <p className="text-sm md:text-base">{body}</p>
      </div>
    </section>
  ) : null
}
