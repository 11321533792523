export const states = {
  ALABAMA: "AL",
  ALASKA: "AK",
  "AMERICAN SAMOA": "AS",
  ARIZONA: "AZ",
  ARKANSAS: "AR",
  CALIFORNIA: "CA",
  COLORADO: "CO",
  CONNECTICUT: "CT",
  DELAWARE: "DE",
  "DISTRICT OF COLUMBIA": "DC",
  "FEDERATED STATES OF MICRONESIA": "FM",
  FLORIDA: "FL",
  GEORGIA: "GA",
  GUAM: "GU",
  HAWAII: "HI",
  IDAHO: "ID",
  ILLINOIS: "IL",
  INDIANA: "IN",
  IOWA: "IA",
  KANSAS: "KS",
  KENTUCKY: "KY",
  LOUISIANA: "LA",
  MAINE: "ME",
  "MARSHALL ISLANDS": "MH",
  MARYLAND: "MD",
  MASSACHUSETTS: "MA",
  MICHIGAN: "MI",
  MINNESOTA: "MN",
  MISSISSIPPI: "MS",
  MISSOURI: "MO",
  MONTANA: "MT",
  NEBRASKA: "NE",
  NEVADA: "NV",
  "NEW HAMPSHIRE": "NH",
  "NEW JERSEY": "NJ",
  "NEW MEXICO": "NM",
  "NEW YORK": "NY",
  "NORTH CAROLINA": "NC",
  "NORTH DAKOTA": "ND",
  "NORTHERN MARIANA ISLANDS": "MP",
  OHIO: "OH",
  OKLAHOMA: "OK",
  OREGON: "OR",
  PALAU: "PW",
  PENNSYLVANIA: "PA",
  "PUERTO RICO": "PR",
  "RHODE ISLAND": "RI",
  "SOUTH CAROLINA": "SC",
  "SOUTH DAKOTA": "SD",
  TENNESSEE: "TN",
  TEXAS: "TX",
  UTAH: "UT",
  VERMONT: "VT",
  "VIRGIN ISLANDS": "VI",
  VIRGINIA: "VA",
  WASHINGTON: "WA",
  "WEST VIRGINIA": "WV",
  WISCONSIN: "WI",
  WYOMING: "WY",
}

export const abbrevToState = {
  AZ: "Arizona",
  AL: "Alabama",
  AK: "Alaska",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DC: "District of Columbia",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
  AS: "American Samoa",
  GU: "Guam",
  MP: "Northern Mariana Islands",
  PR: "Puerto Rico",
  VI: "U.S. Virgin Islands",
  UM: "U.S. Minor Outlying Islands",
}

export const stateRelatedList = [
  "ARIZONA",
  "AZ",
  "ALABAMA",
  "AL",
  "ALASKA",
  "AK",
  "ARKANSAS",
  "AR",
  "CALIFORNIA",
  "CA",
  "COLORADO",
  "CO",
  "CONNECTICUT",
  "CT",
  "DELAWARE",
  "DE",
  "DISTRICT OF COLUMBIA",
  "DC",
  "FLORIDA",
  "FL",
  "GEORGIA",
  "GA",
  "HAWAII",
  "HI",
  "IDAHO",
  "ID",
  "ILLINOIS",
  "IL",
  "INDIANA",
  "IN",
  "IOWA",
  "IA",
  "KANSAS",
  "KS",
  "KENTUCKY",
  "KY",
  "LOUISIANA",
  "LA",
  "MAINE",
  "ME",
  "MARYLAND",
  "MD",
  "MASSACHUSETTS",
  "MA",
  "MICHIGAN",
  "MI",
  "MINNESOTA",
  "MN",
  "MISSISSIPPI",
  "MS",
  "MISSOURI",
  "MO",
  "MONTANA",
  "MT",
  "NEBRASKA",
  "NE",
  "NEVADA",
  "NV",
  "NEW HAMPSHIRE",
  "NH",
  "NEW JERSEY",
  "NJ",
  "NEW MEXICO",
  "NM",
  "NEW YORK",
  "NY",
  "NORTH CAROLINA",
  "NC",
  "NORTH DAKOTA",
  "ND",
  "OHIO",
  "OH",
  "OKLAHOMA",
  "OK",
  "OREGON",
  "OR",
  "PENNSYLVANIA",
  "PA",
  "RHODE ISLAND",
  "RI",
  "SOUTH CAROLINA",
  "SC",
  "SOUTH DAKOTA",
  "SD",
  "TENNESSEE",
  "TN",
  "TEXAS",
  "TX",
  "UTAH",
  "UT",
  "VERMONT",
  "VT",
  "VIRGINIA",
  "VA",
  "WASHINGTON",
  "WA",
  "WEST VIRGINIA",
  "WV",
  "WISCONSIN",
  "WI",
  "WYOMING",
  "WY",
  "AMERICAN SAMOA",
  "AS",
  "GUAM",
  "GU",
  "NORTHERN MARIANA ISLANDS",
  "MP",
  "PUERTO RICO",
  "PR",
  "U.S. VIRGIN ISLANDS",
  "US VIRGIN ISLANDS",
  "VIRGIN ISLANDS",
  "VI",
  "U.S. MINOR OUTLYING ISLANDS",
  "US MINOR OUTLYING ISLANDS",
  "UM",
]

export const stateNameList = [
  "Arizona",
  "Alabama",
  "Alaska",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "District of Columbia",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
  "American Samoa",
  "Guam",
  "Northern Mariana Islands",
  "Puerto Rico",
  "U.S. Virgin Islands",
  "U.S. Minor Outlying Islands",
]
