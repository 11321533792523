import { useRef, useState } from "react"
import {
  Header,
  Menu,
  NavDropDownButton,
  NavMenuButton,
  PrimaryNav,
  Title,
} from "@trussworks/react-uswds"
import Link from "next/link"
import { useTranslations } from "use-intl"
import { LocaleSwitcher } from "../LocaleSwitcher"
import { useOnClickOutside, useWindowSize } from "hooks"
import { MSAttribution } from "components/MSAttribution"

export function MainMenu() {
  const [expanded, setExpanded] = useState(false) // mobile menu
  const [isOpen, setIsOpen] = useState(false) // sub menu
  const size = useWindowSize()

  const menuRef = useRef()

  useOnClickOutside(menuRef, () => setIsOpen(false))

  const t = useTranslations("Shared.Header.MainMenu")

  const onClick = () => setExpanded((prvExpanded) => !prvExpanded)

  const closeMobileMenu = () => {
    setExpanded(false)
  }

  const closeJobSearchMenu = () => {
    if (size.width > 768) {
      setIsOpen(false)
    } else {
      closeMobileMenu()
    }
  }

  const onToggle = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen)
  }

  const jobSearchMenuItems = [
    <Link href="/jobs" key="find-jobs" passHref>
      <a onClick={closeJobSearchMenu}>{t("job_search")}</a>
    </Link>,
    <Link href="/archive" key="archive" passHref>
      <a onClick={closeJobSearchMenu} key="advanced_search">
        {t("advanced_search")}
      </a>
    </Link>,
    <Link href="/reposted-jobs" key="reposted-jobs" passHref>
      <a onClick={closeJobSearchMenu} key="reposted_jobs">
        {t("reposted_jobs")}
      </a>
    </Link>,
    <Link href="/about#how-to-apply" key="how-to-apply" passHref>
      <a onClick={closeJobSearchMenu}>{t("how_to_apply")}</a>
    </Link>,
    <Link href="/feeds" key="feeds" passHref>
      <a onClick={closeJobSearchMenu} key="advanced_search">
        {t("feeds")}
      </a>
    </Link>,
  ]

  const testItemsMenu = [
    <Link href="/" key="home" passHref>
      <a className="usa-nav__link" onClick={closeMobileMenu}>
        <span>{t("home")}</span>
      </a>
    </Link>,
    <>
      <NavDropDownButton
        menuId="jobSearchMenu"
        onToggle={onToggle}
        isOpen={isOpen}
        label={t("find_jobs")}
      />
      <div ref={menuRef}>
        <Menu
          key="one"
          items={jobSearchMenuItems}
          isOpen={isOpen}
          id="jobSearchMenu"
        />
      </div>
    </>,
    <Link href="/about" key="about" passHref>
      <a className="usa-nav__link" onClick={closeMobileMenu}>
        <span>{t("about")}</span>
      </a>
    </Link>,
    <Link href="/contact" key="contact-us" passHref>
      <a className="usa-nav__link" onClick={closeMobileMenu}>
        <span>{t("contact_us")}</span>
      </a>
    </Link>,
    <LocaleSwitcher key="locale" />,
    <MSAttribution key="msft" tw="mt-8 md:-mt-3" />,
  ]

  return (
    <>
      <div className={`usa-overlay ${expanded ? "is-visible" : ""}`}></div>
      <Header basic={true} className="bg-white border-dolFooterPrimary shadow">
        <div className="usa-nav-container">
          <div className="usa-navbar">
            <Link href="/" passHref>
              <Title style={{ cursor: "pointer" }}>
                <h1>
                  <span style={{ fontWeight: 900 }}>SeasonalJobs</span>
                  <span style={{ fontWeight: 400 }}>.dol.gov</span>
                </h1>
              </Title>
            </Link>
            <NavMenuButton onClick={onClick} label={t("menu")} />
            <LocaleSwitcher navMenuButton={true} />
          </div>
          <PrimaryNav
            className="items-center"
            aria-label="Primary Navigation"
            items={testItemsMenu}
            mobileExpanded={expanded}
            onToggleMobileNav={onClick}
          />
        </div>
      </Header>
    </>
  )
}
