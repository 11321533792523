import { appendLocale, milesToKm } from "utils"

export function getJobsFilters({ FILTER, wage, facets, locale }) {
  if (wage && wage !== "all") {
    FILTER.push(`pay_range_desc eq '${wage}'`)
  }
  FILTER.push(`active eq true`)
  FILTER.push(`display eq true`)
  if (facets && facets.length) {
    FILTER.push(`search.in(job_title${appendLocale(locale)}, '${facets}', '|')`)
  }
}

export function getArchiveFilters({ FILTER, job_status }) {
  if (job_status && job_status !== "all") {
    if (job_status === "active") {
      FILTER.push(`active eq true`)
      FILTER.push(`display eq true`)
    } else {
      FILTER.push(`active ne true`)
      FILTER.push(`display ne false`)
    }
  } else {
    FILTER.push(`(display ne false)`)
  }
}

export function getGeoLocation({ lon, lat, radius, type, modifiedLocation }) {
  const lonlat = `geo.distance(coord, geography'POINT(${lon} ${lat})') le ${milesToKm(
    radius
  )}`
  if (type === "just_coords") {
    return lonlat
  } else return `(${lonlat} or worksite_${type} eq '${modifiedLocation}')`
}
