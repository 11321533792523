import Link from "next/link"
import { useRouter } from "next/router"
import { getLangNameFromLocale } from "messages/getLangNameFromLocale"
import { NavMenuButton } from "@trussworks/react-uswds"
import { event } from "utils/googleAnalytics"
import { currentEnv } from "env"

/**
 *
 * @param navMenuButton: whether this locale switcher is
 * on the mobile nav (unexpanded) or not
 * @returns React component
 */
export function LocaleSwitcher({ navMenuButton }) {
  const router = useRouter()
  const { locales, locale: activeLocale } = router
  const otherLocales = locales.filter((locale) => locale !== activeLocale)
  return (
    <>
      {otherLocales.map((locale) => {
        const { pathname, query, asPath } = router
        const languageName = getLangNameFromLocale(locale)

        return (
          <Link
            href={{ pathname, query }}
            as={asPath}
            locale={locale}
            key={locale}
            scroll={false}
          >
            {navMenuButton ? (
              <NavMenuButton label={languageName} />
            ) : (
              <a
                className="usa-nav__link"
                onClick={() => {
                  event({
                    action: `${currentEnv}-switch_language`,
                    label: `click`,
                    category: `switch-to-${languageName}`,
                    value: 1,
                  })
                }}
              >
                <span>{languageName}</span>
              </a>
            )}
          </Link>
        )
      })}
    </>
  )
}
