import { createSlice } from "@reduxjs/toolkit"

export const jobsSlice = createSlice({
  name: "jobs",
  initialState: {
    search: "",
    location: "",
  },
  reducers: {
    setJobsSlice: (state, { payload }) => {
      return {
        ...payload,
      }
    },
    resetJobsSlice: () => {
      return {
        search: "",
        location: "",
      }
    },
  },
})

export const { setJobsSlice, resetJobsSlice } = jobsSlice.actions

export default jobsSlice.reducer
