export function getTitleCase(str) {
  if (!str) return null
  str = str.toLowerCase().split(" ")
  for (var i = 0; i < str.length; i++) {
    str[i] =
      str[i] !== "and"
        ? str[i].charAt(0).toUpperCase() + str[i].slice(1)
        : str[i]
  }
  return str.join(" ")
}

export function getCleanpayRate({ from, to }) {
  const usCurrency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  })

  if (parseInt(to, 10) && to !== from) {
    return `${usCurrency.format(from)}-${usCurrency.format(to)}`
  } else {
    return `${usCurrency.format(from)}`
  }
}

export function getNicePay({ from, to, per, t }) {
  if (!per) return getCleanpayRate({ from, to })
  return t(
    `pay_${
      per.toLowerCase() === "piece rate" ? "piece_rate" : per.toLowerCase()
    }`,
    {
      pay: getCleanpayRate({ from, to }),
    }
  )
}

export function getYesNoFromBoolean({ boolean, t }) {
  return boolean ? t("yes") : t("no")
}

// REMOVES 'AND' AND 'OR' FROM A STRING
export function removeAndOrTokens(str) {
  if (str) {
    const regex = /( [a][n][d] )|( [o][r] )/gi
    str = str.replace(regex, " ")
  }
  return str
}

// ADD FUZZY SEARCH TILDE TO EACH SEARCH TERM PER
// https://docs.microsoft.com/en-us/azure/search/query-lucene-syntax#bkmk_fuzzy
export function fuzzify(str) {
  let splitstr = str.split(" ")

  splitstr.map((term, index) => {
    splitstr[index] = `${term}\~`
  })
  return splitstr.join(" ")
}

// set https:// if not found for web apply link field
export function normalizeProtocol(link) {
  const protocols = ["http://", "https://"]
  const hasProtocol = protocols.some((protocol) => link.startsWith(protocol))

  if (!hasProtocol) {
    link = "https://" + link
  }

  return link
}
