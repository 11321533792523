export const advancedSearchDefaultValues = {
  search: "",
  location: "",
  start_date: "",
  job_type: "all",
  job_status: "all",
  facets: [],
  sort: "relevancy",
}

export const findJobsDefaultValues = {
  search: "",
  location: "",
  start_date: "",
  radius: 100,
  wage: "all",
  job_type: "all",
  facets: [],
  sort: "relevancy",
}
