import { useState } from "react"
import * as yup from "yup"

// Input component to toggle bypassing
const BypassInput = ({ setIsBypassing }) => {
  const handleClick = (e) => {
    const isChecked = e?.target?.checked
    setIsBypassing(isChecked)
  }

  return (
    <div
      className="p-4 mb-2 bg-gray-200 rounded-lg flex items-center hover:text-gray-600"
      onClick={(e) => handleClick(e)}
    >
      <input
        type="checkbox"
        id="vehicle3"
        name="vehicle3"
        value="Boat"
        className="w-5 h-5"
      />
      <label for="vehicle3" className="ml-4 w-full ">
        Bypass field validations?
      </label>
    </div>
  )
}

// Schema for bypassing
const bypassSchema = yup.object().shape({})

// Bypass Hook
export function useBypass({ schema, isTester = false }) {
  const [isBypassing, setIsBypassing] = useState(false)

  if (!isTester) {
    // Default return including Prod
    return { schema, bypassInput: null }
  } else {
    // Lowers return based on bypass toggle
    return {
      schema: isBypassing ? bypassSchema : schema,
      bypassInput: <BypassInput setIsBypassing={setIsBypassing} />,
    }
  }
}
