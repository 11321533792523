import { useTranslations } from "use-intl"

export function SkipLinks() {
  const t = useTranslations("Shared.Header.SkipLinks")
  return (
    <>
      <a id="skip-nav" className="usa-skipnav" href="#main-content">
        {t("skip_main_content")}
      </a>
      <a className="usa-skipnav text-dolBlue" href="#select-language">
        {t("skip_select_language")}
      </a>
    </>
  )
}
