import { createSlice } from "@reduxjs/toolkit"

export const archiveSlice = createSlice({
  name: "archive",
  initialState: {
    search: "",
    location: "",
  },
  reducers: {
    setArchiveSlice: (state, { payload }) => {
      return {
        ...payload,
      }
    },
    resetArchiveSlice: () => {
      return {
        search: "",
        location: "",
      }
    },
  },
})

export const { setArchiveSlice, resetArchiveSlice } = archiveSlice.actions

export default archiveSlice.reducer
