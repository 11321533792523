import { DRUPAL_TRANSLATIONS } from "env"

export async function fetchTranslations({ locale, title }) {
  try {
    const res = await fetch(
      `${DRUPAL_TRANSLATIONS}?_format=json&langcode=${locale}&title=${title}`,
      { mode: "no-cors" }
    )
    const data = await res.json()
    return JSON.parse(data[0].field_translation[0].value)
  } catch (e) {
    console.error(`Could not translate ${title} translations to ${locale}`, e)
  }
}
