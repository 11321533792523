export function appendLocale(locale) {
  if (locale === "en") {
    return ""
  } else return `_${locale}`
}

export function prefixLocale(locale) {
  if (locale === "en") {
    return ""
  } else return `${locale}/`
}
