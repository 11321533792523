export function DepartmentOfLaborBanner() {
  return (
    <div
      className="usa-banner-header"
      style={{
        width: "100%",
        backgroundColor: "#112e51",
        color: "#ffffff",
        padding: "5px 0 5px 0",
      }}
    >
      <div
        className="usa-banner__inner"
        style={{ fontSize: "0.8rem", fontWeight: "bold" }}
      >
        U.S. DEPARTMENT OF LABOR
      </div>
    </div>
  )
}
