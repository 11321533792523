import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  bannerOpen: true,
  coordinates: null,
  locationName: null,
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserLocation: (state, { payload }) => {
      return {
        ...state,
        locationName: payload.locationName,
        coordinates: payload.coordinates,
      }
    },
    resetUserLocation: (state) => {
      return {
        ...state,
        locationName: null,
        coordinates: null,
      }
    },
    toggleBannerOpen: (state) => {
      return {
        ...state,
        bannerOpen: !state.bannerOpen,
      }
    },
  },
})

export const { setUserLocation, resetUserLocation, toggleBannerOpen } =
  userSlice.actions

export default userSlice.reducer
